import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  GetDataListFromServer,
  UpdateDateOnServer,
  UpdateSingleDataOnServer,
  UpdateCancelOnServer,
} from "../lib/apis";
import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  Modal,
  Select,
  Popconfirm,
  Row,
  Table,
  Typography,
  message,
  Flex,
  Tag,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GAME_BALL_LEAGUE_LIST_API,
  GAME_BALL_MATCH_DELETE_API,
  GAME_BALL_MATCH_LIST_API,
  GAME_BALL_TEAM_LIST_API,
  GAME_bALL_MATCH_FINISH_API,
  GAME_BALL_MATCH_CANCEL_API,
} from "../lib/config";
import {
  QUERY_BALL_LEAGUE,
  QUERY_BALL_MATCH,
  QUERY_BALL_TEAM,
} from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import BallMatchCreateForm from "../components/game_ball/game_ball_match_form.js";
import dayjs from "dayjs";
import AddListForm from "../components/game_ball/add_list_form.js";
import FinishListForm from "../components/game_ball/finish_list_form.js";
import UpdateListForm from "../components/game_ball/update_list_form.js";

const { Text } = Typography;
const { Option } = Select;

export default function GameBallMatchPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [allMatches, setAllMatches] = useState({ matches: [], total: 0 });

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    is_finish: "",
    team_name_en: "",
    league_id: "",
    is_deleted: "false",
    order_by: "created_at",
    order_type: "DESC",
  });

  const [leagues, setLeagues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [showModel, setShowModel] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetDataListFromServer(
      GAME_BALL_TEAM_LIST_API,
      queryHandler(
        {
          is_deleted: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_BALL_TEAM,
        {
          page_number: 1,
          per_page: 1000,
        }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setTeams(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    GetDataListFromServer(
      GAME_BALL_LEAGUE_LIST_API,
      queryHandler(
        {
          is_deleted: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_BALL_LEAGUE,
        {
          page_number: 1,
          per_page: 400,
        }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setLeagues(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }, []);

  async function finishMatch(data) {
    setLoading(true);
    await UpdateDateOnServer(
      GAME_bALL_MATCH_FINISH_API,
      showModel?.id || "",
      { match_id: showModel?.id, ...data },
      access_token
    )
      .then(() => {
        setShowModel(false);
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_BALL_MATCH_LIST_API,
      queryHandler(filter, QUERY_BALL_MATCH, { page_number, per_page }),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setList(result);
        setAllMatches(result);
        setPagin({ ...pagin, total: total });
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(GAME_BALL_MATCH_DELETE_API, id, access_token)
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  const cancelMatch = async (id) => {
    setLoading(true);
    await UpdateCancelOnServer(GAME_BALL_MATCH_CANCEL_API, id, access_token)
      .then(() => {
        setLoading(false);
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  };

  const handleFilterChange = (value) => {
    if (value === "all") {
      getDataList(pagin.page_number, pagin.per_page);
    } else {
      const filteredMatches = allMatches.filter((item) =>
        value === "finished"
          ? item.is_finish === true
          : item.is_finish === false
      );
      setList(filteredMatches);
      setPagin({ ...pagin, total: allMatches.length, page_number: 1 });
    }
  };

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "League",
      dataIndex: ["league", "name_en"],
    },
    {
      title: "Team One",
      dataIndex: ["team_one", "name_en"],
      align: "center",
    },
    {
      title: "Team Two",
      dataIndex: ["team_two", "name_en"],
      align: "center",
    },
    {
      title: "Match Date",
      dataIndex: "match_date",
      align: "center",
      render: (match_date) => (
        <Text>{dayjs(match_date).format("YYYY-MM-DD")}</Text>
      ),
    },
    {
      title: "Match Time",
      dataIndex: "match_time",
      align: "center",
      render: (match_time) => <Text>{dayjs(match_time).format("HH:mm")}</Text>,
    },
    {
      title: "display Date",
      dataIndex: "display_date",
      align: "center",
      render: (display_date) => (
        <Text>{dayjs(display_date).format("YYYY-MM-DD")}</Text>
      ),
    },
    {
      title: "Is Finish",
      dataIndex: "is_finish",
      align: "center",
      render: (is_finish) => <Text>{is_finish ? "Finished" : "Ongoing"}</Text>,
    },
    {
      title: "",
      render: (_, user) => {
        return (
          <Row gutter={16} justify={"end"}>
            <Col>
              <Popconfirm
                title={`Are you sure Cancel this Match ?`}
                onConfirm={() => cancelMatch(user.id)}
                okText="Yes"
                cancelText="No"
                disabled={user.is_finish}
              >
                <Button
                  size={ui_size}
                  style={{
                    width: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  type="primary"
                  disabled={user.is_finish}
                >
                  Match Cancel
                </Button>
              </Popconfirm>
            </Col>

            <Col>
              <Button
                size={ui_size}
                style={{ width: "100px" }}
                type="primary"
                disabled={
                  !permissions.includes("BALL_CREATE") || user.is_finish
                }
                onClick={() => {
                  setShowModel(user);
                }}
              >
                Finish
              </Button>
            </Col>
            <Col>
              <Button
                size={ui_size}
                style={{ width: "100px" }}
                type="primary"
                disabled={
                  !permissions.includes("BALL_CREATE") || user.is_finish
                }
                onClick={() => {
                  setEdit(user);
                  setShowForm(true);
                }}
              >
                Edit
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title={`Are you sure Delet this Match ?`}
                onConfirm={() => unlockUser(user.id)}
                okText="Yes"
                cancelText="No"
                disabled={
                  user.is_finish || !permissions.includes("BALL_DELETE")
                }
              >
                <Button
                  size={ui_size}
                  style={{ width: "100px" }}
                  disabled={
                    user.is_finish || !permissions.includes("BALL_DELETE")
                  }
                  type="primary"
                  danger
                >
                  Delete
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setEdit(false);
    setShowForm(false);
    setCurrentForm(null);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col span={8}>
          <Select
            showSearch
            defaultValue="all"
            style={{ width: "50%" }}
            onChange={handleFilterChange}
          >
            <Option value="all">All Matches</Option>
            <Option value="finished">Finished Matches</Option>
            <Option value="ongoing">Ongoing Matches</Option>
          </Select>
        </Col>
        <Col>
          <Button
            size={ui_size}
            type="primary"
            style={{ marginRight: "8px" }}
            onClick={() => {
              setCurrentForm("updateList");
              setShowForm(true);
            }}
          >
            Update List
          </Button>
        </Col>
        <Col>
          <Button
            size={ui_size}
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginRight: "8px" }}
            onClick={() => {
              setCurrentForm("addList");
              setShowForm(true);
            }}
          >
            Add List
          </Button>
        </Col>
        <Col>
          <Button
            size={ui_size}
            type="primary"
            style={{ marginRight: "8px" }}
            onClick={() => {
              setCurrentForm("finishList");
              setShowForm(true);
            }}
          >
            Finish List
          </Button>
        </Col>
        <Col>
          {permissions.includes("BALL_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => {
                setCurrentForm("newMatch");
                setShowForm(true);
              }}
              icon={<PlusOutlined />}
            >
              New Match
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            rowClassName={(record, index) => record.is_big_match ? 'ball_match_big' : 'ball_match_small'}
            expandable={{
              expandedRowRender: (e) => {
                return (
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <Text
                        strong
                        style={{
                          fontSize: "1.5rem",
                          color: e.is_big_match ? "#008000e3" : "#0000ffd2",
                        }}
                      >
                        {e.is_big_match ? "ပွဲကြီး" : "ပွဲသေး"}
                      </Text>
                    </Col>
                    <Col span={24}></Col>
                    <Col span={12}>
                      <Text>
                        ပြားရှုံး / နိုင် {e.key_team_data.name_en} {e.key}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text>
                        {e.team_one.name_en} goal {e.team_one_goal}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text>ဂိုးပေါ် / အောက် {e.goal_plus}</Text>
                    </Col>
                    <Col span={12}>
                      <Text>
                        {e.team_two.name_en} goal {e.team_two_goal}
                      </Text>
                    </Col>
                  </Row>
                );
              },
            }}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                setPagin({
                  ...pagin,
                  page_number: pageNumber,
                  per_page: perPage,
                });
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={closeDrawer}
        title={
          edit
            ? "Edit Match"
            : currentForm === "addList"
            ? "Add Match"
            : currentForm === "finishList"
            ? "Finish Match"
            : currentForm === "updateList"
            ? "Update Match"
            : "New Match"
        }
        destroyOnClose
        placement="right"
        width={"900px"}
      >
        {currentForm === "addList" && <AddListForm closeDrawer={closeDrawer} />}

        {currentForm === "finishList" && (
          <FinishListForm closeDrawer={closeDrawer} />
        )}

        {currentForm === "updateList" && (
          <UpdateListForm closeDrawer={closeDrawer} />
        )}

        {currentForm === "newMatch" || edit ? (
          <BallMatchCreateForm
            edit={edit}
            closeDrawer={closeDrawer}
            carry_leagues={leagues}
            carry_teams={teams}
          />
        ) : null}
      </Drawer>
      <Modal
        name="Match List Finish"
        onCancel={() => setShowModel(false)}
        footer={null}
        open={!!showModel}
        destroyOnClose
      >
        <Form
          onFinish={finishMatch}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="ball match form"
        >
          <Row justify={"space-between"}>
            <Col span={10}>
              <Form.Item
                name="team_one_goal"
                label={`${showModel?.team_one?.name_en} Goal`}
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="team_two_goal"
                label={`${showModel?.team_two?.name_en} Goal`}
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify={"end"}>
                <Col>
                  <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
