import React from "react";
import {
  Descriptions,
  Typography,
  Divider,
  Row,
  Col,
  Card,
  Image,
  Tag,
} from "antd";
import moment from "moment";

const { Text } = Typography;

const DescriptionItem = ({ label, value, itemStyle }) => (
  <Descriptions style={itemStyle} column={1}>
    <Descriptions.Item label={<Text className="text-success">{label}</Text>}>
      <Text>{value}</Text>
    </Descriptions.Item>
  </Descriptions>
);

const renderTeamDetails = (team, isKeyTeam) => (
  <Col
    span={8}
    style={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Image src={team.logo} width={56} preview={false} />
    <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
      <div style={{ flex: 1 }}>
        <Text>{team.name || "...."}</Text>
      </div>
      <div style={{marginLeft:"8px"}}>
        {isKeyTeam && <Text className="text-success">{team.keyMatch}</Text>}
      </div>
    </div>
  </Col>
);
const BallBodyDetail = ({ data }) => {
  const {
    user_id,
    is_finish,
    final_point,
    bit_amount,
    match_date,
    bet_time,
    bet_type,
    team_id,
    team_one_logo,
    team_one_name,
    team_one_id,
    team_one_goal,
    team_two_logo,
    team_two_name,
    team_two_id,
    team_two_goal,
    key_match,
    key_team,
    goal_plus,
  } = data;

  const keyTeamName = team_id === team_one_id ? team_one_name : team_two_name;

  const formatDateTime = (dateTime, type) => {
    return type === "date"
      ? moment(dateTime).format("MMM DD YYYY")
      : moment(dateTime).format("h:mm A");
  };

  return (
    <div>
      <DescriptionItem label="ID" value={user_id} />
      <DescriptionItem label="Game Type" value="Body" />
      <DescriptionItem label="Is Finish?" value={is_finish ? "Yes" : "No"} />
      <DescriptionItem
        label="Final Point"
        value={final_point}
        itemStyle={{ marginTop: "30px" }}
      />
      <DescriptionItem label="Bit Amount" value={bit_amount} />
      <DescriptionItem
        label="Match Date"
        value={formatDateTime(match_date, "date")}
      />
      <DescriptionItem
        label="Match Time"
        value={formatDateTime(match_date, "time")}
      />
      <DescriptionItem
        label="Bet Time"
        value={formatDateTime(bet_time, "time")}
      />

      <Card className="bg-card mt-5">
        <Row align="middle">
          {renderTeamDetails(
            { logo: team_one_logo, name: team_one_name, keyMatch: key_match },
            key_team === team_one_id
          )}
          <Col
            span={8}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Tag
              className="goal-card py-1 px-2 rounded-lg"
              style={{ fontSize: "16px" }}
            >
              {team_one_goal} : {team_two_goal}
            </Tag>
            <Tag
              className="bg-success py-1 px-2 rounded-lg"
              style={{ marginTop: "8px" }}
            >
              {goal_plus}
            </Tag>
          </Col>

          {renderTeamDetails(
            { logo: team_two_logo, name: team_two_name, keyMatch: key_match },
            key_team === team_two_id
          )}
        </Row>
        <Divider style={{ borderColor: "white" }} className="my-4" />
        <Row>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Text className="text-success">Selected</Text>
            {bet_type === "KEY" && (
              <Text style={{ marginTop: "5px" }}>{keyTeamName}</Text>
            )}
            {bet_type === "GOAL_UP" && team_id === team_one_id && (
              <Text>GOAL_UP</Text>
            )}
            {bet_type === "GOAL_UP" && team_id !== team_one_id && (
              <Text>GOAL_DOWN</Text>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default BallBodyDetail;
