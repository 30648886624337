import React, { useEffect, useState } from "react";
import { AGENT_USER_BALL_BODY } from "../../lib/config";
import { useSelector } from "react-redux";
import { queryHandler } from "../../lib/globle_functions";
import { GetDataListFromServer } from "../../lib/apis";
import { QUERY_AGENT_USER_BALL_BODY } from "../../lib/query_objects";
import {
  message,
  Table,
  Tag,
  Typography,
  Button,
  Drawer,
  Form,
  Row,
  Input,
  DatePicker,
} from "antd";
import moment from "moment";
import BallBodyDetail from "./ball_body_detail";

const { Text, Title } = Typography;

const BallBody = ({ data }) => {
  const userId = data.id;

  const { access_token } = useSelector((state) => state.user);
  const [ballBodyList, setBallBodyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);
  const [showDetail, setShowDetail] = useState(null);

  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    id: "",
    order_by: "created_at",
    order_type: "DESC",
  });

  const getDataList = async (page_number, per_page) => {
    setLoading(true);
    await GetDataListFromServer(
      `${AGENT_USER_BALL_BODY}/${userId}`,
      queryHandler(filter, QUERY_AGENT_USER_BALL_BODY, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setBallBodyList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  };

  const closeDrawer = (key) => {
    if(key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    
    setShowDetailDrawer(false);
  };

  
  const tableData = ballBodyList.map((item, index) => ({
    key: item.id,
    user_id: item.id,
    bet_type: item.bet_type,
    bet_time: item.created_at,
    match_date: item.match.display_date,
    teamOne: item.teamOne.name_en,
    teamTwo: item.teamTwo.name_en,
    bit_amount: item.bit_amount,
    is_finish: item.is_finish,
    updated_time: item.updated_at,
    final_point: item.final_point,

    key_team: item.match.key_team,
    key_match: item.match.key,
    goal_plus: item.match.goal_plus,

    team_id: item.team_id,

    team_one_id: item.teamOne.id,
    team_one_logo: item.teamOne.logo,
    team_one_name: item.teamOne.name_en,
    team_one_goal: item.match.team_one_goal,

    team_two_id: item.teamTwo.id,
    team_two_logo: item.teamTwo.logo,
    team_two_name: item.teamTwo.name_en,
    team_two_goal: item.match.team_two_goal,
  }));


  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Bet Time",
      key: "bet_time",
      dataIndex: "bet_time",
      render: (text, record) => (
        <>
          <Text>{moment(record.bet_time).format("MMM DD YYYY")}</Text>
          <br />
          <Text>{moment(record.bet_time).format("h:mm A")}</Text>
        </>
      ),
    },
    {
      title: "Match Date",
      key: "match_date",
      dataIndex: "match_date",
      render: (text, record) => (
        <>
          <Text style={{ color: "#00FF00" }}>
            {moment(record.match_date).format("MMM DD YYYY")}
          </Text>
          <br />
          <Text>{moment(record.match_date).format("h:mm A")}</Text>
        </>
      ),
    },
    {
      title: "Match",
      key: "match",
      dataIndex: "match",
      render: (text, record) => (
        <>
          <Text>{record.teamOne}</Text> <br /> <Text>{record.teamTwo}</Text>
        </>
      ),
    },
    {
      title: "Bit Amount",
      dataIndex: "bit_amount",
    },
    {
      title: "Is Finished?",
      dataIndex: "is_finish",
      key: "is_finish",
      render: (text, record) => {
        const isFinished = record.is_finish;
        const tagColor = isFinished ? "success" : "error";
        const tagText = isFinished ? "Yes" : "No";

        return (
          <Tag color={tagColor} style={{ text }}>
            <Text
              style={{ fontSize: "15px", color: isFinished ? "green" : "red" }}
            >
              {tagText}
            </Text>
          </Tag>
        );
      },
    },
    {
      title: "Updated Time",
      key: "updated_time",
      dataIndex: "updated_time",
      render: (text, record) => (
        <>
          <Text>{moment(record.updated_time).format("MMM DD YYYY")}</Text>
          <br />
          <Text>{moment(record.updated_time).format("h:mm A")}</Text>
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => {
            setShowDetailDrawer(true);
            setShowDetail(record);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ marginBottom: "20px" }}
      >
        <Title level={5} style={{ marginTop: "5px" }}>
          Body History
        </Title>
        <Form layout="inline" clear>
          <Form.Item name="searchTerm">
            <Input
              placeholder="Search User Code"
              allowClear
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item name="matchDate">
            <DatePicker
              placeholder="Select Match Date"
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary">Search</Button>
          </Form.Item>
        </Form>
      </Row>
      <Table
        style={{ marginTop: "20px" }}
        columns={columns}
        dataSource={tableData}
        rowKey="key"
        loading={loading}
        pagination={{
          total: pagin.total,
          defaultCurrent: 1,
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 50],
          onChange: (pageNumber, perPage) => {
            setPagin({ ...pagin, page_number: pageNumber, per_page: perPage });
            getDataList(pageNumber, perPage);
          },
          
        }}
      />

      <Drawer
        open={showDetailDrawer}
        onClose={closeDrawer}
        title="အသေးစိတ် အချက်လက်များ"
        destroyOnClose
        placement="right"
        width={600}
      >
        <BallBodyDetail data={showDetail} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
};

export default BallBody;
