import {
  Table,
  message,
  Button,
  Drawer,
  Input,
  DatePicker,
  Form,
} from 'antd';
import { useEffect, useState } from 'react';
import { getAgentBody } from '../lib/apis';
import { useSelector } from 'react-redux';
import moment from 'moment';
import '../style/ball.css';
import dayjs from 'dayjs';

function AgentBallPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [loading, setLoading] = useState(false);
  const [betLists, setBetLists] = useState([]);
  const [keyTeam, selectKeyTeam] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const showDrawer = (record) => {
    findKeyTeam(record);
    setCurrentRecord(record);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setCurrentRecord(null);
  };

  function findKeyTeam(value) {
    const teams = [value.teamOne, value.teamTwo];
    const filterKeyTeam = teams.filter((team) => team.id == value.team_id);
    selectKeyTeam(...filterKeyTeam);
  }

  const columns = [
    {
      title: 'User Info',
      key: 'user_info',
      render: (text, record) => <>{record.user.user_code}</>,
    },
    {
      title: 'Bet Time',
      key: 'bet_time',
      render: (text, record) => (
        <>
          <span>{moment(record.created_at).format('MMM DD Y')}</span>
          <br />
          {moment(record.created_at).format('H:mm A')}
        </>
      ),
    },
    {
      title: 'Match Date',
      key: 'match_date_time',
      render: (text, record) => (
        <>
          <span style={{ color: '#00FF00' }}>
            {moment(record.match.match_date).format('MMM DD Y')}
          </span>
          <br />
          {moment(record.match.match_time).format('H:mm A')}
        </>
      ),
    },
    {
      title: 'Match',
      key: 'match',
      render: (text, record) => (
        <>
          {record.teamOne.name_en} <br />
          {record.teamTwo.name_en}
        </>
      ),
    },
    {
      title: 'Bit Amount',
      dataIndex: 'bit_amount',
      key: 'bit_amount',
    },
    {
      title: 'Is Finished?',
      dataIndex: 'is_finish',
      key: 'is_finish',
      render: (text) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Updated Time',
      key: 'updated_time',
      render: (text, record) => (
        <>
          {moment(record.updated_at).format('MMM DD Y')}
          <br />
          {moment(record.updated_at).format('hh:mm A')}
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="primary" onClick={() => showDrawer(record)}>
          Detail
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getBetBodyLists();
  }, []);

  async function getBetBodyLists(page = 1, matchDate = '') {
    setLoading(true);
    try {
      const res = await getAgentBody(
        `${process.env.REACT_APP_SERVER_API}/v1/agents/bet/body_lists?page=${page}&perPage=10&matchDate=${matchDate}`,
        access_token
      );
      const data = res.data;
      setLoading(false);
      setBetLists(data.data);
      setTotal(data.total);
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.error || 'Connection Error');
    }
  }

  const onFinish = (value) => {
    const { searchTerm, matchDate } = value;
    const formatDate = matchDate ? dayjs(matchDate).format('YYYY-MM-DD') : '';
    getBetBodyLists(1, formatDate);
  };

  // console.log(currentRecord);

  return (
    <>
      <div className="flex flex items-center justify-between ">
        <h4>Body History</h4>
        <Form
          layout="inline"
          onFinish={onFinish}
          clear
          style={{ marginBottom: 16 }}
        >
          <Form.Item name="searchTerm">
            <Input
              placeholder="Search User Code"
              allowClear
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item name="matchDate">
            <DatePicker
              placeholder="Select Match Date"
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={betLists}
        rowKey={(record) => record.id}
        pagination={{
          responsive: true,
          current: pageNumber,
          total: total,
          onChange: (pageNumber) => {
            setPageNumber(pageNumber);
            getBetBodyLists(pageNumber);
          },
        }}
      />

      <Drawer
        title="အသေးစိတ် အချက်လက်များ"
        placement="right"
        onClose={onClose}
        visible={visible}
        width={500}
      >
        {currentRecord && (
          <div>
            <div className="mb-3r">
              <p className="text-success">
                ID <span>:</span>
                <small className="text-white ms-3"> {currentRecord.id}</small>
              </p>
              <p className="text-success">
                Game Type <span>:</span>
                <span className="text-white ms-3"> Body</span>
              </p>
              <p className="text-success">
                User Code <span>:</span>
                <span className="text-white ms-3">
                  {' '}
                  {currentRecord.user.user_code}
                </span>
              </p>
              <p className="text-success">
                Login Name <span>:</span>
                <span className="text-white ms-3">
                  {' '}
                  {currentRecord.user.login_name}
                </span>
              </p>

              <p className="text-success">
                Is Finish? <span>:</span>
                <span className="text-white ms-3">
                  {' '}
                  {currentRecord.is_finish ? 'YES' : 'NO'}
                </span>
              </p>
            </div>

            <p className="text-success mb-4">
              Final Point <span>:</span>
              <span className="text-white ms-3">
                {' '}
                {currentRecord.final_point}
              </span>
            </p>

            <p className="text-success mb-4">
              Bit Amount <span>:</span>
              <span className="text-white ms-3">
                {' '}
                {currentRecord.bit_amount}
              </span>
            </p>

            <p className="text-success mb-4">
              Match Date <span>:</span>
              <span className="text-white ms-3">
                {' '}
                {moment(currentRecord.match.match_date).format('MMM DD Y')}
              </span>
            </p>

            <p className="text-success mb-4">
              Match Time <span>:</span>
              <span className="text-white ms-3">
                {' '}
                {moment(currentRecord.match.match_time).format('H:mm A')}
              </span>
            </p>

            <p className="text-success mb-4">
              Bet Time <span>:</span>
              <span className="text-white ms-3">
                {' '}
                {moment(currentRecord.created_at).format('H:mm A')}
              </span>
            </p>

            <div className="text-white rounded-2xl mt-5 bg-card py-5">
              <div className="font-semibold flex items-center justify-around">
                <div>
                  <img
                    src={currentRecord?.teamOne.logo}
                    className="object-cover mx-auto h-14 mb-4"
                  />
                  <span className="text-center me-2">
                    {currentRecord?.teamOne?.name_en || '....'}
                  </span>

                  <span className="text-center text-success">
                    {currentRecord.match.key_team == currentRecord.teamOne.id
                      ? currentRecord.match.key
                      : ''}
                  </span>
                </div>

                <div>
                  <div className="goal-card py-1 px-2 rounded-lg mx-2 mb-4">
                    <span className="me-1">
                      {currentRecord?.match.team_one_goal || '0'}
                    </span>
                    :
                    <span className="ms-1">
                      {currentRecord?.match.team_two_goal || '0'}
                    </span>
                  </div>

                  <div className="bg-success py-1 px-2 rounded-lg mx-2">
                    <span className="me-1 text-xs">
                      {currentRecord?.match.goal_plus || '...'}
                    </span>
                  </div>
                </div>

                <div>
                  <img
                    src={currentRecord?.teamTwo.logo}
                    className="object-cover mx-auto h-14 mb-4"
                  />

                  <span className="text-center me-1 text-success">
                    {currentRecord.match.key_team == currentRecord.teamTwo.id
                      ? currentRecord.match.key
                      : ''}
                  </span>
                  <span className="text-center">
                    {currentRecord?.teamTwo?.name_en || '....'}
                  </span>
                </div>
              </div>
              <hr className="my-4" />

              <div className=" px-4 ">
                <p className="text-success font-semibold">Selected</p>

                <span className="mt-2">
                  {currentRecord.bet_type == 'KEY' ? keyTeam?.name_en : ''}
                </span>

                {currentRecord.bet_type == 'GOAL_UP' &&
                currentRecord.team_id == currentRecord.teamOne.id ? (
                  <span>GOAL_UP</span>
                ) : (
                  ''
                )}

                {currentRecord.bet_type == 'GOAL_UP' &&
                currentRecord.team_id != currentRecord.teamOne.id ? (
                  <span>GOAL_DOWN</span>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}

export default AgentBallPage;
